import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import {formatTime} from '@/utils/util'

moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'yyyy-MM-dd HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('date', function (timestamp, format = 'yyyy-MM-dd HH:mm') {
  if (!timestamp) {
    return ''
  }
  const time = new Date(timestamp)
  format = format.replace('yyyy', time.getFullYear())
  format = format.replace('MM', time.getMonth() > 8 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1))
  format = format.replace('dd', time.getDate() > 9 ? time.getDate() : '0' + time.getDate())
  format = format.replace('HH', time.getHours() > 9 ? time.getHours() : '0' + time.getHours())
  format = format.replace('mm', time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes())
  return format
})

Vue.filter('toward', function (value) {
  if (!value) {
    return ''
  }
  return towardOptions[value]
})

Vue.filter('fitup', function (value) {
  if (!value) {
    return ''
  }
  return fitupOptions[value]
})

Vue.filter('brokerage', function (value) {
  if (!value) {
    return ''
  }
  return brokerageOptions[value]
})

Vue.filter('onlineState', function (value) {
  if (!value) {
    return ''
  }
  return onlineState[value].text
})

Vue.filter('articleState', function (value) {
  if (!value) {
    return ''
  }
  return articleState[value].text
})

Vue.filter('articleStateStatus', function (value) {
  if (!value) {
    return ''
  }
  return articleState[value].status
})

Vue.filter('openState', function (value) {
  if (!value) {
    return ''
  }
  return openState[value].text
})

Vue.filter('onlineStateStatus', function (value) {
  if (!value) {
    return ''
  }
  return onlineState[value].status
})

Vue.filter('auditStateStatus', function (value) {
  if (!value) {
    return ''
  }
  return auditState[value].status
})

Vue.filter('auditState', function (value) {
  if (!value) {
    return ''
  }
  return auditState[value].text
})

Vue.filter('reserveState', function (value) {
  if (!value) {
    return ''
  }

  if (reserveState[value]) {
    return reserveState[value].text
  }
  return value
})

Vue.filter('reserveStateStatus', function (value) {
  if (!value) {
    return ''
  }

  if (reserveState[value]) {
    return reserveState[value].status
  }
  return value
})

Vue.filter('validState', function (value) {
  if (!value) {
    return ''
  }
  return validState[value].text
})

Vue.filter('validStateStatus', function (value) {
  if (!value) {
    return ''
  }
  return validState[value].status
})

Vue.filter('propertyUse', function (value) {
  if (!value) {
    return ''
  }
  return propertyUseOptions[value]
})

Vue.filter('area', function (value) {
  if (!value || !areaMap.get(value)) {
    return ''
  }
  return areaMap.get(value).name
})

Vue.filter('city', function (value) {
  if (!value || !cityMap.get(value)) {
    return ''
  }
  return cityMap.get(value).name
})
Vue.filter('preferenceUse', function (value) {
  if (!value) {
    return ''
  }
  return preferenceUseOptions[value]
})
Vue.filter('tags', function (value) {
  if (!value) {
    return ''
  }
  return tagsOptions[value]
})
Vue.filter('room', function (value) {
  if (!value) {
    return ''
  }
  return roomOptions[value]
})
Vue.filter('roomDesc', function (value) {
  if (!value) {
    return ''
  }
  return roomDescOptions[value]
})
Vue.filter('floor', function (value) {
  if (!value) {
    return ''
  }
  return floorOptions[value]
})
Vue.filter('floorDesc', function (value) {
  if (!value) {
    return ''
  }
  return floorDescOptions[value]
})
Vue.filter('price', function (value) {
  if (!value) {
    return ''
  }
  return priceOptions[value]
})
Vue.filter('priceDesc', function (value) {
  if (!value) {
    return ''
  }
  return priceDescOptions[value]
})
// Vue.filter('area', function (value) {
//   if (!value) {
//     return ''
//   }
//   return areaOptions[value]
// })
Vue.filter('areaDesc', function (value) {
  if (!value) {
    return ''
  }
  return areaDescOptions[value]
})
Vue.filter('lastTimeFilter', function (value) {
  if (!value) {
    return ''
  } else {
    return formatTime(value)
  }
})
