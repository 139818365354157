'use strict'
const prod = process.env.VUE_APP_ENV
export const baseUrl = {
    // main: 'http://192.168.8.112:8082/official'
    // main: 'http://192.168.31.100:8082/official',
    // mainProcurement: 'http://192.168.31.100:8086/procurement',
    mainProcurement: 'http://119.23.51.157:8086/procurement',
    // main: 'http://testxuexiao1.fsytss.com/official' // 本地调试用
    main: prod === 'production' ? 'https://api.xuexiao.fsytss.com/official' :
        (prod === 'production-zsy' ? 'http://zhtc.zhongshanyun.cn/official' : 'http://test.mobile.zhtc.zhongshanyun.cn/official')
}
export const loginParam = {
    grantType: 'OPENID',
    clientId: 'customer',
    credential: "",
    secret: '73d1c2341636435f8e364f9db552a255',
    username: ''
}
export const kitchenLoginParam = {
    grantType: 'OPENID_PASSWORD',
    clientId: 'kitchenapp',
    credential: "",
    secret: '73d1c2341636435f8e364f9db552a299',
    username: ''
}
export const appId = prod === 'production' ? 'wx1adbac727573ad83' :
    (prod === 'production-zsy' ? 'wx1c104f731fbba202' :
        (prod === 'dev-zsy' ? 'wx1c104f731fbba202' : 'wx1adbac727573ad83'));
export const appProd = prod;
