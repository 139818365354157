<template>
  <div id="app" ref="div_root">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <div class="service_box" draggable="true" @dragstart="dragstart($event)" @dragend="dragend($event)" :style="`left:${elLeft}px;top:${elTop}px`">
      <img src="./assets/images/service_box.png" style="height: 40px;" alt="" @click="openClienteleServices()">
<!--      <img src="./assets/images/guangfa3500/icon_guangfa3500.png" style="height: 40px; margin-top: 16px;" v-show="showGFActivityIcon()" alt="" @click="openGFActivity()">-->
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
    <div class="loading_all" v-if="loading2">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<script>
import {post} from "./utils/http";
import Vue from "vue";
import {ACCESS_CustomerUser} from "./store/mutation-types";
import { mapState } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      loading: false,
      defaultUrl: 'https://work.weixin.qq.com/kfid/kfcd3359751cfb7281b',

      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量

    }
  },
  mounted() {
    this.elLeft = this.$refs.div_root.clientWidth - 56;
    this.elTop = 540;
  },
  methods: {
    openClienteleServices() {
      this.loading = true;

      let customerUser = Vue.ls.get(ACCESS_CustomerUser);
      if (!customerUser) {
        this.loading = false;
        window.location.href = this.defaultUrl;
        return;
      }

      let businessId = customerUser.businessCustomerUser.business.id

      const url = 'api/business/v2/{businessId}/clientele/services/read'
          .replace('{businessId}', businessId);

      post(url, {}, true).then(res => {
        if (res.data && res.data.clienteleServicesUrl && res.data.clienteleServicesUrl !== '' && res.data.clienteleServicesUrl !== undefined) {
          window.location.href = res.data.clienteleServicesUrl;
        } else {
          window.location.href = this.defaultUrl;
        }

      }).catch(() => {

      }).finally(() => {
        this.loading = false;
      });
    },
    openGFActivity() {
      this.$router.push({name: 'activityGuangfa3500'});
    },

    showGFActivityIcon() {
      let customerUser = Vue.ls.get(ACCESS_CustomerUser);
      if (!customerUser) {
        return false;
      }

      let businessId = customerUser.businessCustomerUser.business.id;
      // 深村小学、石湾一小、三龙湾小学、紫南小学、铁军小学、黎涌小学、冼可澄纪念小学、保利天珀幼儿园、惠雅幼儿园
      // 一天膳事测试学校
      if (
          businessId === '017c0b4f337b06b72c96c1f37bfa026a' ||
          businessId === '017c124eaa6c14f42c96c1f37c0e1ec6' ||
          // businessId === '017b9b99ec221ad92c96c1f37b973694' ||
          businessId === '017c1096128a03a82c96c1f37c0e1ec6' ||
          businessId === '017be2d1e83a68ca2c96c1f37bdfc369' ||
          businessId === '017ba96e9316061c2c96c1f37ba23995' ||
          businessId === '017c7275f66e01bf2c96c1f37c70498f' ||
          // businessId === '018266eaad4c26082c96c1f382573a7f' ||
          // businessId === '01821afe2ad029852c96c1f3820a96e9' ||
          businessId === '017b97396de9000a2c96c1f37b973694') {
        return true;
      }
      return false;
    },

    // 拖拽开始事件
    dragstart(e) {
      console.log(e);
      this.startclientX = e.clientX; // 记录拖拽元素初始位置
      this.startclientY = e.clientY;
    },
    // 拖拽完成事件
    dragend(e) {
      console.log(e);
      let x = e.clientX - this.startclientX; // 计算偏移量
      let y = e.clientY - this.startclientY;
      this.elLeft += x; // 实现拖拽元素随偏移量移动
      this.elTop += y;
    },
  },
  computed: {
    ...mapState(['loading2'])
  }
}
</script>

<style scoped>
.service_box {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 99999999;
  user-select: none;
}

.service_box img {
  width: 100%;
}
</style>