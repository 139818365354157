export const ACCESS_TOKEN = 'Access-Token'
export const ACCESS_CustomerUser = 'ACCESS_CUSTOMER_USER'
export const ACCESS_Customer = 'ACCESS_Customer'

export const CURRENT_BUSINESS_MODULE = 'CURRENT_BUSINESS_MODULE'
export const TUITION_AND_FEES_CONFIRM_MODEL = 'TUITION_AND_FEES_CONFIRM_MODEL'
export const RESERVATION_RECORD_CONFIRM_DISSENT_SELECT = 'RESERVATION_RECORD_CONFIRM_DISSENT_SELECT'
export const NUTRIENT_SOURCE = 'NUTRIENT_SOURCE'
export const NUTRIENT_SOURCE_TRACE = 'NUTRIENT_SOURCE_TRACE'
export const GF_ACTIVITY_ADVERT = 'GF_ACTIVITY_ADVERT_20240525'

export const KITCHEN_ACCESS_TOKEN = 'KITCHEN_ACCESS_TOKEN'
export const KITCHEN_USER = 'KITCHEN_USER'
export const KITCHEN_USER_ROLE = 'KITCHEN_USER_ROLE'
export const KITCHEN_BUSINESS_ID = 'KITCHEN_BUSINESS_ID'
export const KITCHEN_USERNAME = 'KITCHEN_USERNAME'
export const KITCHEN_PASSWORD = 'KITCHEN_PASSWORD'
