import Vue from 'vue'
import router from './router'
import {ACCESS_TOKEN, KITCHEN_ACCESS_TOKEN} from '@/store/mutation-types'
import {setDocumentTitle} from '@/utils/domUtil'
import NProgress from 'nprogress'
import {CURRENT_BUSINESS_MODULE} from "./store/mutation-types"; // progress bar

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = ['login', 'testLogin', 'kitchenLogin', 'switchSystem', 'pushDetail'] // no redirect whitelist
const kitchenWhiteList = ['kitchenLogin'] // no redirect whitelist

router.beforeEach((to, from, next) => {
    NProgress.start(); // start progress bar

    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} `));

    const moduleStr = Vue.ls.get(CURRENT_BUSINESS_MODULE);

    // console.log("access => ", from.name, to.name);
    // console.log("access => kitchen token", Vue.ls.get(KITCHEN_ACCESS_TOKEN));
    // console.log("access => moduleStr", moduleStr);

    if (Vue.ls.get(KITCHEN_ACCESS_TOKEN) === null && from.name === null && to.name === 'login' && moduleStr === 'kitchen') {
        // 智慧厨房登录，需要如此跳转
        next({path: '/kitchen/login', replace: true, query: to.query});
        NProgress.done();
        return;
    }

    if (to.name !== null && to.name.indexOf("kitchen") !== -1) {
        Vue.ls.set(CURRENT_BUSINESS_MODULE, 'kitchen');
        // 智慧厨房
        if (Vue.ls.get(KITCHEN_ACCESS_TOKEN)) {
            next();
        } else {
            if (kitchenWhiteList.includes(to.name)) {
                next();
            } else {
                next({path: '/kitchen/login', replace: true});
            }
        }
    } else {
        // 校园膳食
        if (Vue.ls.get(ACCESS_TOKEN)) {
            next();
        } else {
            if (whiteList.includes(to.name)) {
                next();
            } else {
                Vue.ls.set(CURRENT_BUSINESS_MODULE, 'school');
                next({path: '/login', replace: true});
            }
        }
    }

    // if (moduleStr === 'kitchen') {
    //     if (Vue.ls.get(KITCHEN_ACCESS_TOKEN)) {
    //         if (from.name === null && to.name === 'login') {
    //             next({path: '/kitchen/login', replace: true, query: to.query})
    //             return;
    //         }
    //         // console.log("access => ", from.name, to.name);
    //         next()
    //     } else {
    //         // console.log("access not => ", from.name, to.name);
    //         if (from.name === null && to.name === 'login') {
    //             next({path: '/kitchen/login', replace: true, query: to.query})
    //             return;
    //         }
    //         if (whiteList.includes(to.name)) {
    //             next()
    //         } else {
    //             next({path: '/kitchen/login', replace: true});
    //         }
    //     }
    //
    // } else if (moduleStr === 'school') {
    //     if (Vue.ls.get(ACCESS_TOKEN)) {
    //         next()
    //     } else {
    //         if (whiteList.includes(to.name)) {
    //             next()
    //         } else {
    //             next({path: '/login', replace: true});
    //         }
    //     }
    // } else {
    //     Vue.ls.set, 'school');
    //     next({path: '/login', replace: true});
    // }

    NProgress.done();

})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
